@import url("https://use.typekit.net/bnm1ran.css");

$font-face:ibm-plex-sans,sans-serif;
$font-face-title: brandon-grotesque, sans-serif;
$font-face-condensed:ibm-plex-sans-condensed, sans-serif;
$light: 300;
$normal: 400;
$semibold: 700;
$bold: 800;
$dark-blue:#004f6f;
$light-blue:#629db8;
$white:white;
$dark-grey:#3c3c3b;
$device-large: 1680px;
$device-medium: 1440px;
$device-small: 1024px;
$device-mobile: 768px;

$header-height-big:155px;
$header-height-small:80px;

$device-large: 1680px;
$device-medium: 1440px;
$device-small: 1024px;
$device-mobile: 768px;

p{
    font-family: $font-face;
}

.h2-main{
    font-size: 2.5em;
    text-transform: uppercase;
    font-family: $font-face-title;
    font-weight: $semibold;
    color:$light-blue !important;
    position: relative;
    margin:30px 0px 40px 58px;
    padding:0;
   // border: 1px solid red;
    top:0;
    left:0px;
    display: inline-block;
}
.h2-in{
    //animation-name: title-in;
    //@animation-duration: 1s;
}
.h2-out{
    //animation-name: title-out;
    //animation-duration: 1s;
}
.h2-in::after{
    content:'';
    width:100px;
    height:6px;
    position: absolute;
   // border:2px white solid;
    background-color: $light-blue;
    bottom:-10px;
    right:0px;  
    animation-name: title-decoration-in;
    animation-duration: 1s;
}
.h2-out::after{
    content:'';
    width:100px;
    height:8px;
    position: absolute;
   // border:2px white solid;
    background-color: $light-blue;
    bottom:-10px;
    right:0px;  
    animation-name: title-decoration-out;
    animation-duration: 1s;
}
@keyframes title-in{
    from{margin-left:-20px}
    to{margin-left: 30px};
}
@keyframes title-out{
    from{margin-left:30px}
    to{margin-left: -20px};
}
@keyframes title-decoration-in{
    from{width:5px;right:-5px;}
    to{width:100px;right:0px}
}
@keyframes title-decoration-out{
    from{width:100px;}
    to{width:100px;}
}
@media screen and (max-width:$device-mobile){
    h1,h2{
        position: absolute;
        z-index: 10;
        top:$header-height-small + 30px;
        margin-left:30px;
        //text-shadow: 4px 4px 0px rgba(0,0,0,0.3);
        left:20px;
        font-size: 2em;
        color: white;
        text-transform: uppercase;
        font-family: $font-face-title;
        font-weight: $semibold;
    
    }
    h2::after{
        content:'';
        width:100px;
        height:5px;
        position: absolute;
       // border:2px white solid;
        background-color: $light-blue;
        bottom:-5px;
        right:0px;  
    }
}
@media screen and (max-width:450px){
    .h2-main{
        font-size: 1.8em;
        text-transform: uppercase;
        font-family: $font-face-title;
        font-weight: $semibold;
        color:$light-blue !important;
        position: relative;
        margin:20px 0px 20px 10px;
        padding:0;
        width:75vw;
        //border: 1px solid red;
        line-height: 1em;
        top:0;
        left:12vw;
        display: inline-block;
    }
    h2::after{
        content:'';
        width:100px;
        height:6px;
        position: absolute;
       // border:2px white solid;
        background-color: $light-blue;
        bottom:-5px;
        left:0px;  
    }
    .h2-in::after{
        content:'';
        width:100px;
        height:6px;
        position: absolute;
       // border:2px white solid;
        background-color: $light-blue;
        bottom:-10px;
        left:0px;  
        animation-name: title-decoration-in;
        animation-duration: 1s;
    }
    .h2-out::after{
        content:'';
        width:100px;
        height:6px;
        position: absolute;
       // border:2px white solid;
        background-color: $light-blue;
        bottom:-10px;
        left:0px;  
        animation-name: title-decoration-out;
    }
       
}