@import "./master.scss";


.logo-header{
  width:150px;
}
.cont-loading{
  display: table-cell;
  vertical-align:middle;
  margin:0;
  padding: 0;
  background-color: $dark-blue;
  text-align: center;
  width: 100vw;
  height: 100vh;

  & p{
    font-weight: $bold;
    width:100%;
    text-align: center;
    font-size: 2em;
    color:white;
    text-transform: uppercase;
  }
  
}
.footer{
  margin:0;
  padding:0;
  color: white;

}
.logo-load{
  width: 20vw;
  height:auto;
  display: inline-block;
  margin:20px;
  //filter:grayscale(100%);
  filter:brightness(5000%)
}
.footer-row{
  background-color: $dark-blue;
  height: auto;
 
  width:100vw;
   & div{
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    & p{
      margin-top:3vh;
      font-weight: $bold;
      display: inline-block;
    }
    & a{
      color:$light-blue;
      font-weight: $semibold;
    }
   }
}
.little{
  font-size: 0.8em;
  font-style: italic;
  font-weight: regular;
  margin-left:10vw;
  margin-right: 10vw;
}
@media screen and (max-width: 450px) {
  .footer-row{
    background-color: $dark-blue;
    height: auto;
    text-align: center;
    width:100vw;
    padding-top:10px;
     & .col{
      margin:5px;
      display: table-cell;
     // border:1px solid red;
      vertical-align: middle;
      text-align: center;
      & p{
        margin-top:4vh;
        font-weight: $bold;
        display: inline-block;
      }
      & a{
        color:$light-blue;
        font-weight: $semibold;
      }
     }
  }
}