@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
@import url(https://use.typekit.net/bnm1ran.css);
p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

html {
  margin: 0;
  padding: 0;
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 800; }

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.logo-header {
  width: 150px; }

.cont-loading {
  display: table-cell;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  background-color: #004f6f;
  text-align: center;
  width: 100vw;
  height: 100vh; }
  .cont-loading p {
    font-weight: 800;
    width: 100%;
    text-align: center;
    font-size: 2em;
    color: white;
    text-transform: uppercase; }

.footer {
  margin: 0;
  padding: 0;
  color: white; }

.logo-load {
  width: 20vw;
  height: auto;
  display: inline-block;
  margin: 20px;
  -webkit-filter: brightness(5000%);
          filter: brightness(5000%); }

.footer-row {
  background-color: #004f6f;
  height: auto;
  width: 100vw; }
  .footer-row div {
    display: table-cell;
    vertical-align: middle;
    text-align: center; }
    .footer-row div p {
      margin-top: 3vh;
      font-weight: 800;
      display: inline-block; }
    .footer-row div a {
      color: #629db8;
      font-weight: 700; }

.little {
  font-size: 0.8em;
  font-style: italic;
  font-weight: regular;
  margin-left: 10vw;
  margin-right: 10vw; }

@media screen and (max-width: 450px) {
  .footer-row {
    background-color: #004f6f;
    height: auto;
    text-align: center;
    width: 100vw;
    padding-top: 10px; }
    .footer-row .col {
      margin: 5px;
      display: table-cell;
      vertical-align: middle;
      text-align: center; }
      .footer-row .col p {
        margin-top: 4vh;
        font-weight: 800;
        display: inline-block; }
      .footer-row .col a {
        color: #629db8;
        font-weight: 700; } }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.de-mots-header {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 800;
  height: 155px;
  background: white;
  transition: height 0.5s, background-color 0.5s,padding-top 0.5s; }
  .de-mots-header .logo-header {
    position: absolute;
    height: 137px;
    left: 60px;
    top: 5px;
    -webkit-filter: initial;
            filter: initial;
    transition: height 0.5s, -webkit-filter 0.5s;
    transition: height 0.5s, filter 0.5s;
    transition: height 0.5s, filter 0.5s, -webkit-filter 0.5s; }

.de-mots-header-small {
  font-family: ibm-plex-sans, sans-serif;
  font-weight: 800;
  height: 80px;
  padding-top: 0px;
  background: #004f6f;
  transition: height 0.5s, background-color 0.5s; }
  .de-mots-header-small .logo-header {
    position: absolute;
    left: 60px;
    top: 8px;
    height: 60px;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
    transition: height 0.5s, scale 0.5s,top 0.5s, -webkit-filter 0.5s;
    transition: height 0.5s, filter 0.5s,scale 0.5s,top 0.5s;
    transition: height 0.5s, filter 0.5s,scale 0.5s,top 0.5s, -webkit-filter 0.5s; }
  .de-mots-header-small .header-menu {
    margin-top: 0px;
    padding-top: 0px; }
    .de-mots-header-small .header-menu .header-items {
      color: #629db8 !important; }
    .de-mots-header-small .header-menu .header-items-active {
      color: white !important; }
    .de-mots-header-small .header-menu .header-items:hover {
      color: white !important; }
    .de-mots-header-small .header-menu .header-items:hover::after {
      background-color: white;
      transition: background-color 0.5s; }

.header-baseline {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .header-baseline p {
    clear: both;
    display: block;
    font-family: brandon-grotesque, sans-serif; }

.header-menu {
  width: 100%;
  margin-top: 40px;
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important; }
  .header-menu ul {
    display: table;
    height: 100%;
    line-height: 0.3em; }
  .header-menu .header-items {
    width: 150px;
    display: inline-block;
    color: #629db8 !important;
    font-size: 1.1em;
    text-align: center;
    margin-top: 10px;
    padding-top: 10px;
    line-height: 0.8em;
    transition: color 0.5s; }
  .header-menu .header-items-active {
    width: 150px;
    display: inline-block;
    color: #004f6f !important;
    font-size: 1.1em;
    text-align: center;
    margin-top: 10px;
    padding-top: 10px;
    line-height: 0.8em;
    transition: color 0.5s; }
  .header-menu .header-items:hover {
    color: #004f6f !important; }
  .header-menu .header-items::after {
    content: " ";
    position: relative;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0);
    width: 80px;
    height: 5px;
    margin-top: 10px;
    transition: background-color 0.5s, width 0.5s; }
  .header-menu .header-items-active::after {
    content: " ";
    display: inline-block;
    background-color: #629db8;
    width: 80px;
    height: 5px; }
  .header-menu .header-items:hover::after {
    content: " ";
    position: relative;
    display: inline-block;
    background-color: #004f6f;
    width: 100px;
    height: 5px;
    margin-top: 10px;
    transition: background-color 0.5s; }

@media screen and (max-width: 768px) {
  .de-mots-header {
    height: 80px;
    margin: 0;
    padding: 0; }
    .de-mots-header .logo-header {
      height: 80px; }
  .de-mots-header-small {
    margin: 0;
    padding: 0; }
    .de-mots-header-small .menu-burger {
      border-color: #004f6f !important;
      margin-right: 20px;
      margin-top: 10px;
      background-color: white; }
      .de-mots-header-small .menu-burger .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,79,111, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
    .de-mots-header-small .de-mots-items {
      background-color: #004f6f;
      width: 100vw;
      margin: 0;
      padding: 0; }
  .menu-burger {
    border-color: #004f6f !important;
    margin-right: 20px;
    margin-top: 10px;
    background-color: white; }
    .menu-burger .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,79,111, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
  .menu-burger-opened {
    background-color: #004f6f;
    margin-right: 20px;
    margin-top: 10px; }
    .menu-burger-opened .navbar-toggler-icon {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E"); }
  .de-mots-items {
    background-color: white;
    width: 50px;
    margin-top: 30px; }
  .header-baseline {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-end;
            align-items: flex-end;
    -webkit-justify-content: center;
            justify-content: center;
    float: none;
    vertical-align: top;
    width: 50%; }
    .header-baseline .header-items {
      margin-right: 40px;
      margin-top: 2px;
      margin-bottom: 10px;
      text-align: right;
      line-height: 0.5em;
      font-size: 1em; }
    .header-baseline .header-items::after {
      content: " ";
      position: relative;
      display: block;
      background-color: #629db8;
      width: 70px;
      height: 5px;
      left: 70px;
      margin-left: 10px;
      margin-bottom: -4px; }
    .header-baseline .header-items:hover::after {
      content: " ";
      display: inline-block;
      background-color: #004f6f;
      width: 3px;
      height: 20px; }
    .header-baseline .header-items-active::after {
      content: " ";
      display: block;
      background-color: #629db8;
      position: relative;
      width: 80px;
      height: 5px;
      width: 70px;
      height: 5px;
      left: 30px;
      margin-left: 10px;
      margin-top: 10px;
      margin-bottom: 5px; }
    .header-baseline .header-items:hover {
      color: #004f6f !important;
      margin-right: 30px;
      line-height: 0.5em; }
  .hidden-menu {
    visibility: hidden; } }

.invisible-burger {
  opacity: 0; }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .header-menu {
    font-size: 1em; }
    .header-menu .header-items {
      width: 100px;
      font-size: 1em; }
    .header-menu .header-items-active {
      width: 100px;
      font-size: 1.1em; } }

@media screen and (max-width: 450px) {
  .de-mots-header {
    font-family: ibm-plex-sans, sans-serif;
    font-weight: 800;
    height: 100px;
    background: white;
    transition: height 0.5s, background-color 0.5s,padding-top 0.5s; }
    .de-mots-header .logo-header {
      position: absolute;
      height: 80px;
      left: 20px;
      top: 5px;
      -webkit-filter: initial;
              filter: initial;
      transition: height 0.5s, -webkit-filter 0.5s;
      transition: height 0.5s, filter 0.5s;
      transition: height 0.5s, filter 0.5s, -webkit-filter 0.5s; }
  .de-mots-header-small {
    font-family: ibm-plex-sans, sans-serif;
    font-weight: 800;
    height: 80px;
    padding-top: 0px;
    background: #004f6f;
    transition: height 0.5s, background-color 0.5s; }
    .de-mots-header-small .logo-header {
      position: absolute;
      left: 0px;
      top: 10px;
      height: 50px;
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
      transition: height 0.5s, scale 0.5s,top 0.5s, -webkit-filter 0.5s;
      transition: height 0.5s, filter 0.5s,scale 0.5s,top 0.5s;
      transition: height 0.5s, filter 0.5s,scale 0.5s,top 0.5s, -webkit-filter 0.5s; } }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.header-manifeste {
  margin: 0;
  margin-top: 105px;
  padding: 0px; }

.h2-manifeste {
  position: absolute;
  z-index: 10;
  top: 175px;
  margin-left: 30px;
  left: 60px;
  font-size: 2.5em;
  color: white;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  opacity: 0; }

.video-cover {
  opacity: 1; }

.video-IE {
  height: 100%; }

.over-blend {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #004f6f;
  mix-blend-mode: multiply; }

.header-carousel {
  margin: 0;
  padding: 0;
  width: auto;
  max-height: 520px;
  min-height: 20px;
  overflow: hidden; }

.caption-carousel {
  position: absolute;
  z-index: 13;
  top: 150px;
  left: 330px;
  width: 50%;
  margin-left: 0px;
  padding-right: 60px;
  margin-top: 5px;
  font-family: ibm-plex-sans, sans-serif;
  color: white; }
  .caption-carousel .item-caption {
    margin-top: 10px;
    margin-bottom: 20px;
    margin-right: 70px;
    font-size: 1.2em;
    width: 100%; }
    .caption-carousel .item-caption strong {
      color: white;
      font-size: 1em; }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .caption-carousel {
    left: 20vw;
    top: 140px;
    width: 60vw;
    font-size: 1rem; } }

@media screen and (max-width: 768px) {
  .h2-manifeste {
    position: absolute;
    z-index: 10;
    top: 130px;
    margin-left: 2vw; }
  .caption-carousel {
    position: absolute;
    z-index: 13;
    left: 10vw;
    top: 90px;
    width: 80vw;
    font-size: 1rem; }
    .caption-carousel .item-caption {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 70px;
      font-size: 1em;
      line-height: 1em;
      width: 100%; }
      .caption-carousel .item-caption strong {
        color: white;
        font-size: 0.9em; } }

@media screen and (max-width: 450px) {
  .h2-manifeste {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 10px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    opacity: 0; }
  .header-carousel {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    max-height: 520px;
    overflow: hidden; }
  .caption-carousel {
    position: absolute;
    z-index: 13;
    left: 6vw;
    top: 50px;
    width: 95vw;
    font-size: 0.8rem; }
    .caption-carousel .item-caption {
      margin-top: 10px;
      margin-bottom: 10px;
      margin-right: 70px;
      font-size: 1em;
      line-height: 1em;
      width: 100%; }
      .caption-carousel .item-caption strong {
        color: white;
        font-size: 0.9em; } }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.fix {
  border: 1px solid green; }

.cont-approche {
  margin-bottom: 80px; }

.title-approche {
  color: #629db8 !important;
  position: relative;
  margin: 20px 0px 20px 0px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.app-incipit {
  position: relative;
  display: block;
  top: 0px;
  left: 320px;
  height: auto;
  width: 60%;
  margin-bottom: 40px;
  font-family: ibm-plex-sans, sans-serif;
  color: #004f6f; }
  .app-incipit em {
    font-weight: 800; }

.app-row {
  margin-left: 50px;
  margin-right: 50px; }

.app-card {
  border: none;
  border-radius: 0px;
  cursor: pointer; }

.card-p {
  margin-bottom: 0px;
  padding: 0 10px 10px 10px;
  line-height: 1.2em; }

.item-0 {
  color: #43e1af; }

.item-1 {
  color: #ff1400; }

.item-2 {
  color: #3c44fd; }

.item-3 {
  color: #e5d028; }

.app-card-label div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.fond-card {
  background-color: #F9F9F9;
  opacity: 0.9; }

.card-img {
  border-radius: 0%;
  height: auto; }

.card-over-0 {
  position: absolute;
  background-color: white;
  top: 150px;
  left: 50px;
  padding: 10px;
  width: 700px;
  z-index: 200;
  font-size: 0.9em;
  color: #43e1af; }

.card-over-1 {
  position: absolute;
  background-color: white;
  top: 150px;
  left: 50px;
  padding: 10px;
  width: 700px;
  z-index: 200;
  font-size: 0.9em;
  color: #ff1400; }

.card-over-2 {
  position: absolute;
  background-color: white;
  top: 150px;
  left: -100px;
  padding: 10px;
  width: 600px;
  z-index: 200;
  font-size: 0.9em;
  color: #3c44fd; }

.card-over-3 {
  position: absolute;
  background-color: white;
  top: 150px;
  right: 30px;
  padding: 10px;
  width: 700px;
  z-index: 200;
  font-size: 0.9em;
  color: #e5d028; }

.app-card-label h3 {
  position: absolute;
  top: 40%;
  width: 100%;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 800;
  font-size: 1.4em;
  text-transform: uppercase;
  text-align: center;
  opacity: 1; }

@media screen and (max-width: 768px) {
  .app-incipit {
    top: 0px;
    left: 10vw;
    height: auto;
    width: 80vw;
    margin-bottom: 40px;
    font-family: ibm-plex-sans, sans-serif;
    color: #004f6f; }
    .app-incipit em {
      font-weight: 800; }
  .app-row {
    margin-left: 2vw; }
  .app-row > div {
    margin-bottom: 10px;
    width: 40vw; }
  .card-over-0 {
    top: 120px;
    left: 5vw;
    width: 90vw; }
  .card-over-1 {
    top: 120px;
    left: -45vw !important;
    width: 90vw; }
  .card-over-2 {
    top: 120px;
    left: 5vw;
    width: 90vw; }
  .card-over-3 {
    top: 120px;
    right: -5vw;
    width: 90vw; }
  .app-card {
    width: 30vw;
    margin: auto; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .app-row {
    margin-left: 5vw;
    margin-right: 5vw; }
  .card-p {
    font-size: 1em; }
  .app-card-label h3 {
    font-size: 1.2em; }
  .card-over-0 {
    top: 120px; }
  .card-over-1 {
    position: absolute;
    background-color: white;
    top: 120px;
    left: 10px;
    padding: 10px;
    width: 650px;
    z-index: 200;
    font-size: 0.9em;
    color: #ff1400; }
  .card-over-2 {
    position: absolute;
    background-color: white;
    top: 120px;
    left: -100px;
    padding: 10px;
    width: 600px;
    z-index: 200;
    font-size: 0.9em;
    color: #3c44fd; }
  .card-over-3 {
    top: 120px; } }

@media screen and (max-width: 450px) {
  .cont-approche {
    margin-bottom: 10px;
    padding: 0;
    margin-left: 0px;
    margin-right: 0px; }
  .card-over-0 {
    top: 70px;
    width: 100vw;
    left: -5vw;
    padding: 5px; }
  .card-over-1 {
    position: absolute;
    background-color: white;
    top: 80px;
    left: 5vw;
    padding: 5px;
    width: 90w;
    z-index: 200;
    font-size: 0.9em;
    color: #ff1400; }
  .card-over-2 {
    position: absolute;
    background-color: white;
    top: 120px;
    left: -5vw;
    padding: 5px;
    width: 100vw;
    z-index: 200;
    font-size: 0.9em;
    color: #3c44fd; }
  .card-over-3 {
    top: 120px;
    padding: 5px;
    left: -50vw;
    width: 100vw; }
  .app-incipit {
    top: 0px;
    left: 0vw;
    height: auto;
    width: 75vw;
    margin-bottom: 20px;
    font-family: ibm-plex-sans, sans-serif;
    color: #004f6f; }
    .app-incipit em {
      font-weight: 800; }
  .app-row {
    margin-left: 0vw;
    margin-right: 0vw;
    margin-bottom: 0px; }
  .app-row > .col {
    cursor: pointer;
    margin-bottom: 30px; }
  .card-p {
    font-size: 0.9em; }
  .app-card-label h3 {
    font-size: 1em; }
  .app-incipit {
    left: 12vw; } }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.project-viewer-0 {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 60px;
  width: 70vw;
  height: auto;
  max-height: 72vh;
  background-color: black;
  overflow: hidden; }
  .project-viewer-0 .carousel-indicators {
    position: absolute !important;
    bottom: -1vw; }
    .project-viewer-0 .carousel-indicators li {
      opacity: 1;
      -webkit-filter: drop-shadow(1 1 10px #333);
              filter: drop-shadow(1 1 10px #333); }
    .project-viewer-0 .carousel-indicators .active {
      background-color: #ead43e; }

.project-viewer-1 {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 60px;
  width: 70vw;
  height: auto;
  max-height: 72vh;
  background-color: black;
  overflow: hidden; }
  .project-viewer-1 .carousel-indicators {
    position: absolute !important;
    bottom: -1vw; }
    .project-viewer-1 .carousel-indicators li {
      opacity: 1;
      -webkit-filter: drop-shadow(1 1 10px #333);
              filter: drop-shadow(1 1 10px #333); }
    .project-viewer-1 .carousel-indicators .active {
      background-color: #ff3a35; }

.project-viewer-2 {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 60px;
  width: 70vw;
  height: auto;
  max-height: 72vh;
  background-color: black;
  overflow: hidden; }
  .project-viewer-2 .carousel-indicators {
    position: absolute !important;
    bottom: -1vw; }
    .project-viewer-2 .carousel-indicators li {
      opacity: 1;
      -webkit-filter: drop-shadow(1 1 10px #333);
              filter: drop-shadow(1 1 10px #333); }
    .project-viewer-2 .carousel-indicators .active {
      background-color: #4de0b0; }

.project-viewer-3 {
  position: absolute;
  z-index: 2;
  top: 50px;
  left: 60px;
  width: 70vw;
  height: auto;
  max-height: 72vh;
  background-color: black;
  overflow: hidden; }
  .project-viewer-3 .carousel-indicators {
    position: absolute !important;
    bottom: -1vw; }
    .project-viewer-3 .carousel-indicators li {
      opacity: 1;
      -webkit-filter: drop-shadow(1 1 10px #333);
              filter: drop-shadow(1 1 10px #333); }
    .project-viewer-3 .carousel-indicators .active {
      background-color: #3f4fff; }

.exit {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 800;
  font-size: 1.5rem;
  position: absolute;
  z-index: 4;
  top: 60px;
  left: 80px;
  cursor: pointer; }

.h3-0 {
  color: #004f6f;
  display: inline-block; }

.h3-1 {
  color: #004f6f;
  display: inline-block; }

.h3-2 {
  color: #004f6f;
  display: inline-block; }

.h3-3 {
  color: #004f6f;
  display: inline-block; }

.h3-0::after {
  content: '';
  position: absolute;
  bottom: -12px;
  width: 70px;
  height: 5px;
  left: 2px;
  background-color: #ead43e; }

.h3-1::after {
  content: '';
  position: absolute;
  bottom: -12px;
  width: 70px;
  height: 5px;
  left: 2px;
  background-color: #ff3a35; }

.h3-2::after {
  content: '';
  position: absolute;
  bottom: -12px;
  width: 70px;
  height: 5px;
  left: 2px;
  background-color: #4de0b0; }

.h3-3::after {
  content: '';
  position: absolute;
  bottom: -12px;
  width: 70px;
  height: 5px;
  left: 2px;
  background-color: #3f4fff; }

.c0 {
  color: #ead43e; }

.c1 {
  color: #ff3a35; }

.c2 {
  color: #4de0b0; }

.c3 {
  color: #3f4fff; }

.item-conteneur {
  height: 100%; }

.projet-img {
  max-width: 100%;
  width: auto;
  height: auto;
  padding: 0;
  display: block;
  margin: 0 auto; }

.projet-p {
  position: absolute;
  z-index: 20;
  max-width: 40vw;
  left: 76vw;
  top: 200px;
  font-size: 0.9rem;
  color: #004f6f;
  padding-left: 3px;
  max-height: 500px;
  overflow: scroll; }
  .projet-p h3 {
    font-size: 1.1em;
    font-weight: 700; }

.title-project {
  position: absolute;
  z-index: 15;
  left: 76vw;
  top: 150px;
  font-size: 1.1em;
  font-weight: 700; }

.video-react .video-react-big-play-button {
  position: absolute;
  z-index: 30;
  width: 10vw !important;
  height: 10vw !important;
  margin-top: 21vh;
  margin-left: 30vw;
  text-align: center;
  font-size: 6em !important;
  color: white;
  background-color: #004f6f !important;
  border-radius: 5vw !important;
  padding-top: 5vw; }

.video-react-control-bar {
  background-color: rgba(0, 79, 111, 0.9) !important;
  font-family: ibm-plex-sans, sans-serif !important; }

.carousel-control-prev {
  height: 54vh; }
  .carousel-control-prev .carousel-control-prev-icon {
    margin-top: 50px;
    width: 30px;
    height: 30px;
    opacity: 1; }

.carousel-control-next {
  height: 54vh; }
  .carousel-control-next .carousel-control-next-icon {
    margin-top: 50px;
    width: 30px;
    height: 30px;
    opacity: 1; }

@media screen and (max-width: 768px) {
  .title-project {
    position: absolute;
    z-index: 15;
    left: 10vw;
    top: 4vh;
    font-size: 1.5em;
    font-weight: 700; }
  .projet-p {
    position: absolute;
    padding: 10px;
    margin: 0;
    z-index: 20;
    max-width: 92vw;
    left: 4vw;
    top: 68vh;
    font-size: 0.9rem;
    line-height: 1.2;
    color: #004f6f;
    max-height: 200px;
    color: white;
    background-color: rgba(0, 79, 111, 0.9);
    overflow: scroll; }
    .projet-p h3 {
      font-size: 1em;
      font-style: italic;
      font-weight: 700; }
  .exit {
    font-family: brandon-grotesque, sans-serif;
    font-weight: 800;
    font-size: 2rem;
    position: absolute;
    z-index: 5;
    top: 3vh;
    left: 5vw;
    cursor: pointer; }
  .project-viewer-0 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 4vw;
    width: 92vw;
    max-height: 65vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-0 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-0 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-0 .carousel-indicators .active {
        background-color: #ead43e; }
  .project-viewer-1 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 4vw;
    width: 92vw;
    max-height: 65vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-1 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-1 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-1 .carousel-indicators .active {
        background-color: #ff3a35; }
  .project-viewer-2 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 4vw;
    width: 92vw;
    max-height: 65vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-2 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-2 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-2 .carousel-indicators .active {
        background-color: #4de0b0; }
  .project-viewer-3 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 4vw;
    width: 92vw;
    max-height: 65vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-3 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-3 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-3 .carousel-indicators .active {
        background-color: #3f4fff; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .title-project {
    position: absolute;
    z-index: 15;
    left: 72vw;
    top: 140px;
    font-size: 1em;
    font-weight: 700; }
  .projet-p {
    position: absolute;
    z-index: 20;
    max-width: 40vw;
    left: 74vw;
    top: 180px;
    font-size: 0.8rem;
    color: #004f6f;
    line-height: 1.2em;
    padding-left: 3px;
    max-height: 60vh;
    overflow: scroll;
    background-color: rgba(255, 255, 255, 0.8); }
    .projet-p ul {
      padding: 15px; }
    .projet-p li {
      padding-left: 0px;
      margin-bottom: 5px; }
  .video-react .video-react-big-play-button {
    position: absolute;
    z-index: 30;
    width: 10vw !important;
    height: 10vw !important;
    margin-top: 18vh;
    margin-left: 29vw;
    text-align: center;
    font-size: 5em !important;
    color: white;
    background-color: #004f6f !important;
    border-radius: 5vw !important;
    padding-top: 5vw; }
  .carousel-control-prev {
    height: 45vh; }
    .carousel-control-prev .carousel-control-prev-icon {
      margin-top: 50px;
      width: 30px;
      height: 30px;
      opacity: 1; }
  .carousel-control-next {
    height: 45vh; }
    .carousel-control-next .carousel-control-next-icon {
      margin-top: 50px;
      width: 30px;
      height: 30px;
      opacity: 1; } }

@media only screen and (min-width: 1200px) {
  .project-viewer-0 {
    position: absolute;
    z-index: 2;
    top: 50px;
    left: 60px;
    width: 52vw;
    height: auto;
    max-height: 530px;
    background-color: black;
    overflow: hidden; }
    .project-viewer-0 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-0 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-0 .carousel-indicators .active {
        background-color: #ead43e; }
  .project-viewer-1 {
    position: absolute;
    z-index: 2;
    top: 50px;
    left: 60px;
    width: 52vw;
    height: auto;
    max-height: 530px;
    background-color: black;
    overflow: hidden; }
    .project-viewer-1 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-1 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-1 .carousel-indicators .active {
        background-color: #ff3a35; }
  .project-viewer-2 {
    position: absolute;
    z-index: 2;
    top: 50px;
    left: 60px;
    width: 52vw;
    height: auto;
    max-height: 530px;
    background-color: black;
    overflow: hidden; }
    .project-viewer-2 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-2 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-2 .carousel-indicators .active {
        background-color: #4de0b0; }
  .project-viewer-3 {
    position: absolute;
    z-index: 2;
    top: 50px;
    left: 60px;
    width: 52vw;
    height: auto;
    max-height: 530px;
    background-color: black;
    overflow: hidden; }
    .project-viewer-3 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-3 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-3 .carousel-indicators .active {
        background-color: #3f4fff; }
  .projet-p {
    position: absolute;
    z-index: 20;
    max-width: 42vw;
    left: 57vw;
    top: 200px;
    font-size: 0.9rem;
    color: #004f6f;
    padding-left: 3px;
    max-height: 500px;
    overflow: scroll; }
  .title-project {
    position: absolute;
    z-index: 15;
    left: 57vw;
    top: 160px;
    font-size: 1.1em;
    font-weight: 700; }
  .video-react .video-react-big-play-button {
    position: absolute;
    z-index: 30;
    width: 8vw !important;
    height: 8vw !important;
    margin-top: 21vh;
    margin-left: 22vw;
    text-align: center;
    font-size: 7.5em !important;
    color: white;
    background-color: #004f6f !important;
    border-radius: 4vw !important;
    padding-top: 5vw; } }

@media screen and (max-width: 450px) {
  .title-project {
    position: absolute;
    z-index: 15;
    left: 8vw;
    top: 3vh;
    font-size: 1.4em;
    font-weight: 700; }
  .projet-p {
    position: absolute;
    padding: 10px;
    margin: 0;
    z-index: 20;
    max-width: 96vw;
    left: 2vw;
    top: 35vh;
    font-size: 0.9rem;
    line-height: 1.2;
    color: #004f6f;
    max-height: 40vh;
    color: white;
    background-color: rgba(0, 79, 111, 0.9);
    overflow: scroll; }
    .projet-p h3 {
      font-size: 0.95em;
      font-style: italic;
      font-weight: 700; }
    .projet-p ul {
      padding: 11px; }
    .projet-p li {
      padding-left: 0px;
      margin-bottom: 5px; }
  .exit {
    font-family: brandon-grotesque, sans-serif;
    font-weight: 800;
    font-size: 1.8rem;
    position: absolute;
    z-index: 5;
    top: 2vh;
    left: 3vw;
    cursor: pointer; }
  .project-viewer-0 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 2vw;
    width: 96vw;
    max-height: 33vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-0 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-0 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-0 .carousel-indicators .active {
        background-color: #ead43e; }
  .project-viewer-1 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 2vw;
    width: 96vw;
    max-height: 33vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-1 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-1 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-1 .carousel-indicators .active {
        background-color: #ff3a35; }
  .project-viewer-2 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 2vw;
    width: 96vw;
    max-height: 33vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-2 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-2 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-2 .carousel-indicators .active {
        background-color: #4de0b0; }
  .project-viewer-3 {
    position: absolute;
    z-index: 4;
    top: 3vw;
    left: 2vw;
    width: 96vw;
    max-height: 33vh;
    height: 100%;
    background-color: black;
    overflow: hidden; }
    .project-viewer-3 .carousel-indicators {
      position: absolute !important;
      bottom: -1vw; }
      .project-viewer-3 .carousel-indicators li {
        opacity: 1;
        -webkit-filter: drop-shadow(1 1 10px #333);
                filter: drop-shadow(1 1 10px #333); }
      .project-viewer-3 .carousel-indicators .active {
        background-color: #3f4fff; }
  .carousel-control-prev {
    height: 24vh; }
  .carousel-control-next {
    height: 24vh; }
  .video-react .video-react-big-play-button {
    position: absolute;
    z-index: 30;
    width: 30vw !important;
    height: 30vw !important;
    margin-top: 8vh;
    margin-left: 28vw;
    text-align: center;
    font-size: 7.5em !important;
    color: white;
    background-color: #004f6f !important;
    border-radius: 15vw !important;
    padding-top: 5vw; } }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.thumb-item-0 {
  padding: 0 0 0 0;
  margin: 15px;
  cursor: pointer;
  width: 100%;
  overflow: hidden; }
  .thumb-item-0 img {
    width: 100%;
    height: auto;
    object-fit: fill; }
  .thumb-item-0 .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ead43e;
    opacity: 0.7; }

.thumb-item-1 {
  padding: 0 0 0 0;
  margin: 15px;
  cursor: pointer; }
  .thumb-item-1 img {
    width: 100%;
    height: auto; }
  .thumb-item-1 .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ff3a35;
    opacity: 0.7; }

.thumb-item-2 {
  padding: 0 0 0 0;
  margin: 15px;
  cursor: pointer; }
  .thumb-item-2 img {
    width: 100%;
    height: auto; }
  .thumb-item-2 .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #4de0b0;
    opacity: 0.7; }

.thumb-item-3 {
  padding: 0 0 0 0;
  margin: 15px;
  cursor: pointer; }
  .thumb-item-3 img {
    width: 100%;
    height: auto; }
  .thumb-item-3 .cover {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #3f4fff;
    opacity: 0.7; }

.p-item {
  position: absolute;
  display: inline-block;
  text-align: right;
  font-weight: 800;
  line-height: 1em;
  font-size: 1.1rem;
  top: 15%;
  width: 70%;
  color: white;
  left: 30px;
  margin-right: 40px; }

.p-item::after {
  content: '';
  position: absolute;
  width: 50px;
  right: 0px;
  bottom: -15px;
  height: 4px;
  background-color: white; }

.p-client {
  position: absolute;
  font-weight: 800;
  text-align: right;
  font-style: italic;
  line-height: 1em;
  font-size: 0.9rem;
  bottom: 0%;
  width: 100%;
  color: white;
  right: 12%; }

@media screen and (max-width: 768px) {
  .thumb-item-0 {
    width: 40vw; }
  .thumb-item-1 {
    width: 40vw; }
  .thumb-item-2 {
    width: 40vw; }
  .thumb-item-3 {
    width: 40vw; }
  .p-item {
    font-size: 1.3em;
    width: 95%;
    left: -15px;
    top: 30px; }
  .p-item::after {
    content: '';
    position: absolute;
    width: 40px;
    right: 0px;
    bottom: -8px;
    height: 3px; }
  .p-client {
    position: absolute;
    font-weight: 800;
    text-align: right;
    font-style: italic;
    line-height: 1em;
    font-size: 1em;
    bottom: 40px;
    width: 90%;
    color: white;
    right: 10%; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .p-item {
    font-size: 0.9em;
    width: 95%;
    left: 0px; }
  .p-item::after {
    content: '';
    position: absolute;
    width: 40px;
    right: 0px;
    bottom: -8px;
    height: 3px; }
  .p-client {
    position: absolute;
    font-weight: 800;
    text-align: right;
    font-style: italic;
    line-height: 1em;
    font-size: 0.8em;
    bottom: -2px;
    width: 90%;
    color: white;
    right: 5%; } }

p {
  font-family: ibm-plex-sans, sans-serif; }

.h2-main {
  font-size: 2.5em;
  text-transform: uppercase;
  font-family: brandon-grotesque, sans-serif;
  font-weight: 700;
  color: #629db8 !important;
  position: relative;
  margin: 30px 0px 40px 58px;
  padding: 0;
  top: 0;
  left: 0px;
  display: inline-block; }

.h2-in::after {
  content: '';
  width: 100px;
  height: 6px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-in;
          animation-name: title-decoration-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

.h2-out::after {
  content: '';
  width: 100px;
  height: 8px;
  position: absolute;
  background-color: #629db8;
  bottom: -10px;
  right: 0px;
  -webkit-animation-name: title-decoration-out;
          animation-name: title-decoration-out;
  -webkit-animation-duration: 1s;
          animation-duration: 1s; }

@-webkit-keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@keyframes title-in {
  from {
    margin-left: -20px; }
  to {
    margin-left: 30px; } }

@-webkit-keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@keyframes title-out {
  from {
    margin-left: 30px; }
  to {
    margin-left: -20px; } }

@-webkit-keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@keyframes title-decoration-in {
  from {
    width: 5px;
    right: -5px; }
  to {
    width: 100px;
    right: 0px; } }

@-webkit-keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@keyframes title-decoration-out {
  from {
    width: 100px; }
  to {
    width: 100px; } }

@media screen and (max-width: 768px) {
  h1, h2 {
    position: absolute;
    z-index: 10;
    top: 110px;
    margin-left: 30px;
    left: 20px;
    font-size: 2em;
    color: white;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700; }
  h2::after {
    content: '';
    width: 100px;
    height: 5px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    right: 0px; } }

@media screen and (max-width: 450px) {
  .h2-main {
    font-size: 1.8em;
    text-transform: uppercase;
    font-family: brandon-grotesque, sans-serif;
    font-weight: 700;
    color: #629db8 !important;
    position: relative;
    margin: 20px 0px 20px 10px;
    padding: 0;
    width: 75vw;
    line-height: 1em;
    top: 0;
    left: 12vw;
    display: inline-block; }
  h2::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -5px;
    left: 0px; }
  .h2-in::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-in;
            animation-name: title-decoration-in;
    -webkit-animation-duration: 1s;
            animation-duration: 1s; }
  .h2-out::after {
    content: '';
    width: 100px;
    height: 6px;
    position: absolute;
    background-color: #629db8;
    bottom: -10px;
    left: 0px;
    -webkit-animation-name: title-decoration-out;
            animation-name: title-decoration-out; } }

.fix {
  border: 1px solid green; }

.blue-container {
  margin: 0;
  padding: 0; }

.cont-reas {
  margin-bottom: 80px; }

.rea-figure {
  margin: 0; }

.title-reas {
  color: white !important;
  margin-bottom: 20px; }

.cont-logos {
  margin-left: 5vw;
  margin-right: 5vw;
  margin-top: 30px;
  margin-bottom: 70px; }
  .cont-logos div {
    display: table-cell !important;
    vertical-align: middle;
    text-align: center; }
    .cont-logos div img {
      display: block;
      width: 15vw;
      height: auto;
      margin: auto;
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-filter: grayscale(100%);
              filter: grayscale(100%); }

.reas-incipit {
  position: relative;
  display: block;
  top: 0px;
  left: 325px;
  height: auto;
  width: 60%;
  margin-top: 5px;
  margin-bottom: 30px;
  font-family: ibm-plex-sans, sans-serif;
  font-size: 1em;
  color: white; }
  .reas-incipit em {
    font-weight: 800; }

.client-incipit {
  position: relative;
  display: block;
  top: 0px;
  left: 530px;
  height: auto;
  width: 60%;
  margin-top: 5px;
  margin-bottom: 30px;
  font-family: ibm-plex-sans, sans-serif;
  font-size: 1em;
  color: white;
  font-weight: 800; }

.reas-incipit-cont {
  width: 100%; }

.reas-header {
  background-color: #629db8;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px; }

.title-reas::after {
  background-color: white; }

.rea-img {
  position: relative;
  max-width: 70vw;
  width: auto;
  height: auto;
  padding: 0;
  margin: 45px 0 0px 45px;
  cursor: pointer; }

.alpha {
  opacity: 0.1; }

.rea-content {
  position: absolute;
  z-index: 3;
  top: 50px;
  left: 60vw;
  width: 38vw;
  font-family: brandon-grotesque, sans-serif; }
  .rea-content h3 {
    font-weight: 800;
    font-size: 2.4em;
    text-transform: uppercase; }

.c-0 {
  color: #ead43e; }

.c-0::after {
  content: "";
  background-color: #ead43e;
  position: absolute;
  bottom: -5px;
  left: 5vw;
  width: 90px;
  height: 6px; }

.c-1 {
  color: #ff3a35; }

.c-2 {
  color: #4de0b0; }

.c-3 {
  color: #3f4fff; }

.l-0 {
  color: #ead43e; }

.l-1 {
  color: #ff3a35; }

.l-2 {
  color: #4de0b0; }

.l-3 {
  color: #3f4fff; }

.c-1::after {
  content: "";
  background-color: #ff3a35;
  position: absolute;
  bottom: -5px;
  left: 5vw;
  width: 90px;
  height: 6px; }

.c-2::after {
  content: "";
  background-color: #4de0b0;
  position: absolute;
  bottom: -5px;
  left: 5vw;
  width: 90px;
  height: 6px; }

.c-3::after {
  content: "";
  background-color: #3f4fff;
  position: absolute;
  bottom: -5px;
  left: 5vw;
  width: 90px;
  height: 6px; }

.rea-label {
  position: absolute;
  z-index: 10;
  left: 65vw;
  top: 150px;
  width: 30vw;
  font-size: 1.1em;
  font-family: ibm-plex-sans, sans-serif; }

.rea-projet {
  margin: 0px 0 5px 45px; }
  .rea-projet h4 {
    display: inline-block;
    font-family: ibm-plex-sans, sans-serif;
    font-size: 1em;
    font-weight: 800;
    width: auto;
    padding-bottom: 5px;
    border-bottom: 3px solid; }

.form-cont {
  background-color: #629db8;
  padding: 0px;
  margin-bottom: 0px;
  margin-top: 0px; }
  .form-cont .col {
    margin-left: 295px; }
    .form-cont .col p {
      font-family: ibm-plex-sans, sans-serif;
      color: white;
      font-weight: 800;
      font-size: 1.2em; }
    .form-cont .col .wpcf7 {
      margin-top: 20px;
      width: 40vw; }
      .form-cont .col .wpcf7 form > p {
        font-size: 1em;
        font-weight: 700;
        padding-bottom: 5px;
        position: relative; }
        .form-cont .col .wpcf7 form > p span {
          display: inline-block;
          margin-top: 5px; }
          .form-cont .col .wpcf7 form > p span input {
            border: 0px;
            width: 40vw;
            padding: 5px;
            font-family: ibm-plex-sans, sans-serif;
            color: #004f6f; }
          .form-cont .col .wpcf7 form > p span textArea {
            border: 0px;
            width: 40vw;
            padding: 5px;
            font-family: ibm-plex-sans, sans-serif;
            color: #004f6f;
            resize: none; }

.form-contact {
  margin-top: 20px;
  width: 45vw; }
  .form-contact label {
    font-family: ibm-plex-sans, sans-serif;
    font-size: 1.1em;
    font-weight: 700;
    color: white; }

.wpcf7-submit {
  border: 0px;
  background-color: #004f6f;
  padding: 5px;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  position: relative;
  width: 150px;
  right: 0px; }

.wpcf7-submit:hover {
  background-color: white;
  color: #004f6f; }

.caption-photo {
  font-family: ibm-plex-sans, sans-serif;
  color: #004f6f;
  font-size: 1.3em;
  font-weight: 800;
  line-height: 1em; }
  .caption-photo span {
    font-size: 0.7em;
    font-weight: 300; }

.cover-bio {
  overflow: hidden;
  color: #004f6f;
  font-size: 1em;
  max-width: 60vw; }
  .cover-bio h3 {
    font-weight: 800;
    font-size: 1.1em;
    margin-top: 20px; }
  .cover-bio p {
    margin: 0px; }
  .cover-bio ul > li {
    list-style: none;
    font-weight: 400;
    font-size: 1em;
    margin-bottom: 3px; }
  .cover-bio li:before {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    background-image: url("http://de-mots.com/wp/wp-content/uploads/2019/05/puce.png");
    background-size: contain;
    margin-right: 5px;
    margin-left: 0px; }

@media screen and (max-width: 768px) {
  .reas-incipit {
    left: 8vw;
    height: auto;
    width: 80vw; }
  .client-incipit {
    left: 8vw; }
  .form-cont {
    background-color: #629db8;
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px; }
    .form-cont .col {
      margin-left: 8vw; }
    .form-cont .wpcf7 {
      margin-top: 20px;
      width: 80vw !important; }
      .form-cont .wpcf7 form > p {
        width: 100% !important;
        font-size: 1em;
        font-weight: 700;
        padding-bottom: 5px;
        position: relative; }
        .form-cont .wpcf7 form > p span {
          display: inline-block;
          margin-top: 5px; }
          .form-cont .wpcf7 form > p span input {
            border: 0px;
            width: 80vw !important;
            padding: 5px;
            font-family: ibm-plex-sans, sans-serif;
            color: #004f6f; }
          .form-cont .wpcf7 form > p span textArea {
            border: 0px;
            width: 80vw !important;
            padding: 5px;
            font-family: ibm-plex-sans, sans-serif;
            color: #004f6f;
            resize: none; }
  .wpcf7-submit {
    border: 0px;
    background-color: #004f6f;
    padding: 5px;
    color: white;
    font-weight: 800;
    font-size: 1.5em;
    text-transform: uppercase;
    position: relative;
    width: 77vw;
    height: 8vh;
    right: 0px; }
  .rea-img {
    position: relative;
    max-width: 90vw;
    width: auto;
    height: auto;
    padding: 0;
    margin: 2vw 0 0px 2vw;
    cursor: pointer; }
  .rea-content {
    position: absolute;
    z-index: 3;
    top: 3vw;
    left: 5vw;
    width: 50vw;
    font-family: brandon-grotesque, sans-serif; }
    .rea-content h3 {
      font-weight: 800;
      font-size: 2.2em;
      line-height: 1.1em;
      text-transform: uppercase; }
  .c-0::after {
    content: "";
    bottom: -5px;
    left: 0vw;
    width: 120px;
    height: 5px; }
  .c-1::after {
    content: "";
    bottom: -5px;
    left: 0vw;
    width: 120px;
    height: 5px; }
  .c-2::after {
    content: "";
    bottom: -5px;
    left: 0vw;
    width: 120px;
    height: 5px; }
  .c-3::after {
    content: "";
    bottom: -5px;
    left: 0vw;
    width: 120px;
    height: 5px; }
  .rea-label {
    position: absolute;
    padding: 15px;
    z-index: 10;
    left: 5vw;
    top: 15vw;
    width: 85vw;
    font-size: 1em;
    line-height: 1.2em;
    background-color: rgba(255, 255, 255, 0.5);
    font-family: ibm-plex-sans, sans-serif; }
  .rea-projet {
    margin: 0px 0 5px 2vw; }
    .rea-projet h4 {
      display: inline-block;
      font-family: ibm-plex-sans, sans-serif;
      font-size: 1em;
      font-weight: 800;
      width: auto;
      padding-bottom: 5px;
      border-bottom: 3px solid; }
  .form-contact {
    margin-top: 20px;
    width: 77vw; }
    .form-contact label {
      font-family: ibm-plex-sans, sans-serif;
      font-size: 1.1em;
      font-weight: 700;
      color: white; }
  .cover-bio {
    overflow: hidden;
    color: #004f6f;
    font-size: 0.9em;
    max-width: 88vw;
    text-align: justify; }
    .cover-bio h3 {
      font-weight: 800;
      font-size: 1em;
      margin-top: 20px; }
    .cover-bio p {
      margin: 0px; }
    .cover-bio ul {
      padding-left: 10px; }
    .cover-bio ul > li {
      list-style: none;
      font-weight: 400;
      font-size: 0.9em;
      margin-bottom: 3px; }
    .cover-bio li:before {
      content: '';
      display: inline-block;
      height: 8px;
      width: 8px;
      background-image: url("http://de-mots.com/wp/wp-content/uploads/2019/05/puce.png");
      background-size: contain;
      margin-right: 5px;
      margin-left: 0px; } }

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .rea-content {
    position: absolute;
    font-family: brandon-grotesque, sans-serif;
    left: 61vw; }
    .rea-content h3 {
      font-weight: 800;
      font-size: 2.2em;
      text-transform: uppercase;
      line-height: 1.1em; }
  .rea-label {
    position: absolute;
    z-index: 10;
    left: 66vw;
    top: 150px;
    width: 30vw;
    font-size: 1em;
    font-family: ibm-plex-sans, sans-serif;
    line-height: 1.2em; } }

@media only screen and (min-width: 1200px) {
  .rea-img {
    position: relative;
    max-width: 52vw;
    width: auto;
    height: auto;
    padding: 0;
    margin: 45px 0 0px 45px;
    cursor: pointer; }
  .rea-content {
    position: absolute;
    z-index: 3;
    top: 50px;
    left: 47vw;
    width: 50vw;
    font-family: brandon-grotesque, sans-serif; }
    .rea-content h3 {
      font-weight: 800;
      font-size: 2.4em;
      text-transform: uppercase; }
  .rea-label {
    position: absolute;
    z-index: 10;
    left: 52vw;
    top: 145px;
    width: 34vw;
    font-size: 1.1em;
    font-family: ibm-plex-sans, sans-serif; } }

@media screen and (max-width: 450px) {
  .rea-content {
    position: absolute;
    z-index: 3;
    top: 3vw;
    left: 8vw;
    width: 80vw;
    font-family: brandon-grotesque, sans-serif; }
    .rea-content h3 {
      font-weight: 800;
      font-size: 1.8em;
      line-height: 1em;
      text-transform: uppercase; }
  .rea-label {
    position: absolute;
    padding: 15px;
    z-index: 10;
    left: 5vw;
    top: 15vh;
    width: 91vw;
    font-size: 1em;
    line-height: 1.2em;
    background-color: rgba(255, 255, 255, 0.7);
    font-family: ibm-plex-sans, sans-serif; }
  .reas-incipit {
    left: 15vw; }
  .client-incipit {
    left: 15vw; }
  .cont-logos {
    margin-left: 1vw;
    margin-right: 1vw;
    margin-top: 30px;
    margin-bottom: 30px; }
    .cont-logos div {
      display: table-cell !important;
      vertical-align: middle;
      text-align: center; }
      .cont-logos div img {
        display: block;
        width: 100%;
        height: auto;
        margin: auto;
        position: relative;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        -webkit-filter: grayscale(100%);
                filter: grayscale(100%); }
  .form-cont {
    background-color: #629db8;
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px; }
    .form-cont .col {
      margin-left: 10vw; }
      .form-cont .col p {
        width: 75vw; }
      .form-cont .col .wpcf7 {
        margin-top: 20px;
        width: 75vw !important; }
        .form-cont .col .wpcf7 form > p {
          width: 100% !important;
          font-size: 1em;
          font-weight: 700;
          padding-bottom: 5px;
          position: relative; }
          .form-cont .col .wpcf7 form > p span {
            display: inline-block;
            margin-top: 5px; }
            .form-cont .col .wpcf7 form > p span input {
              border: 0px;
              width: 75vw !important;
              padding: 5px;
              font-family: ibm-plex-sans, sans-serif;
              color: #004f6f; }
            .form-cont .col .wpcf7 form > p span textArea {
              border: 0px;
              width: 75vw !important;
              padding: 5px;
              font-family: ibm-plex-sans, sans-serif;
              color: #004f6f;
              resize: none; }
  .wpcf7-submit {
    border: 0px;
    background-color: #004f6f;
    padding: 5px;
    color: white;
    font-weight: 800;
    font-size: 1.5em;
    text-transform: uppercase;
    position: relative;
    width: 75vw;
    height: 8vh;
    right: 0px; }
  .form-contact {
    margin-top: 20px;
    width: 75vw; }
    .form-contact label {
      font-family: ibm-plex-sans, sans-serif;
      font-size: 1em;
      font-weight: 700;
      color: white; } }

.row-propos {
  margin: 30px; }

.img-r {
  padding-right: 15px;
  padding-bottom: 18px;
  width: 100%;
  height: auto; }

.img-fond-r {
  background-image: url("http://de-mots.com/wp/wp-content/uploads/2019/05/bg-blue.png");
  background-size: 150px 150px;
  background-repeat: no-repeat;
  background-position: 100% 100%;
  padding-right: 10px;
  padding-bottom: 10px; }

