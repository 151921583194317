@import "../../master.scss";

.thumb-item-0{
    //width: 100px !important;
    padding: 0 0 0 0;
    margin :15px;
    cursor: pointer;
    //border:1px solid red;
    width:100%;
    //height:20vh;
    overflow:hidden;

    & img{
        width:100%;
        height:auto;
        object-fit: fill;
        //overflow:hidden;
    }
    & .cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ead43e;
        opacity: 0.7;
    }
}
.thumb-item-1{
    padding: 0 0 0 0;
    margin :15px;
    cursor: pointer;
    & img{
        width:100%;
        height:auto;
    }
    & .cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #ff3a35;
        opacity: 0.7;
    }
}
    
.thumb-item-2{
    padding: 0 0 0 0;
    margin :15px;
    cursor: pointer;
    & img{
        width:100%;
        height:auto;
    }
    & .cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color:#4de0b0;
        opacity: 0.7;
    }
}
.thumb-item-3{
    padding: 0 0 0 0;
    margin :15px;
    cursor: pointer;
    & img{
        width:100%;
        height:auto;
    }
    & .cover{
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #3f4fff;
        opacity: 0.7;
    }
}
.p-item{
    position:absolute;
    display: inline-block;;
    text-align: right;
    font-weight: $bold;
    line-height: 1em;
    font-size: 1.1rem;
    top:15%;
    width:70%;
    color:white;
    left:30px;
    margin-right: 40px;
    //border: 1px solid red;
}
.p-item::after{
    content:'';
    position: absolute;
    width: 50px;
    right: 0px;
    bottom:-15px;
    height: 4px;
    background-color: white;
}
.p-client{
    position:absolute;
    font-weight: $bold;
    text-align: right;
    font-style: italic;
    line-height: 1em;
    font-size: 0.9rem;
    bottom:0%;
    width:100%;
    color:white;
    right:12%;
    //border: 1px solid red;
}
@media screen and (max-width: $device-mobile) {
    .thumb-item-0{
        width:40vw;
    }
    .thumb-item-1{
        width:40vw;
    }
    .thumb-item-2{
        width:40vw;
    }.thumb-item-3{
        width:40vw;
    }
    .p-item{
        font-size: 1.3em;
        width:95%;
        left:-15px;
        top:30px;
    }
    .p-item::after{
        content:'';
        position: absolute;
        width: 40px;
        right: 0px;
        bottom:-8px;
        height: 3px;
    }
    .p-client{
        position:absolute;
        font-weight: $bold;
        text-align: right;
        font-style: italic;
        line-height: 1em;
        font-size: 1em;
        bottom:40px;
        width:90%;
        color:white;
        right:10%;
        //border: 1px solid red;
    }


}
@media only screen and (min-width: 768px) and (max-width:1024px) {
    .p-item{
        font-size: 0.9em;
        width:95%;
        left:0px;
    }
    .p-item::after{
        content:'';
        position: absolute;
        width: 40px;
        right: 0px;
        bottom:-8px;
        height: 3px;
    }
    .p-client{
        position:absolute;
        font-weight: $bold;
        text-align: right;
        font-style: italic;
        line-height: 1em;
        font-size: 0.8em;
        bottom:-2px;
        width:90%;
        color:white;
        right:5%;
        //border: 1px solid red;
    }
}