@import "../master.scss";
.header-manifeste{
   //border:1px red solid;
    margin: 0;
    margin-top:($header-height-big - 50px);
   // margin-top: 500px;
    padding: 0px;
    //background-color: blue;
    //height: 300px;;

}
.h2-manifeste{
    position: absolute;
    z-index: 10;
    top:$header-height-big + 20px;
    margin-left:30px;
    //text-shadow: 4px 4px 0px rgba(0,0,0,0.3);
    left:60px;
    font-size: 2.5em;
    color: white;
    text-transform: uppercase;
    font-family: $font-face-title;
    font-weight: $semibold;
    opacity: 0;

}
.video-cover{
    opacity:1;
    //border: 1px solid red;
}
.video-IE{
    height:100%;
}
.over-blend{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //
    background-color: $dark-blue;
    mix-blend-mode: multiply;
    //background:  linear-gradient(to bottom, rgba(1,1,1,1) 0%, rgba(0,80,111,1) 40%, rgba(0,80,111,1) 60%, rgba(0,0,0,1) 100%);
    
}
.header-carousel{

    margin:0;
    padding: 0;
    width: auto;
    max-height: 520px;
    min-height:20px;
    overflow: hidden;
    //border:1px solid blue;
    //background-color: $dark-blue;
   // background:  linear-gradient(to bottom, rgba(1,1,1,1) 10%, rgba(0,80,111,1) 40%, rgba(0,80,111,1) 84%, rgba(0,0,0,1) 100%);
    //background-blend-mode: multiply;
}
.caption-carousel{
    position: absolute;
    z-index: 13;
    //top:$header-height-big;
    //border:1px solid red;
    top:150px;
    left:330px;
    width:50%;
    margin-left:0px;
    padding-right: 60px;
    margin-top: 5px;
    font-family: $font-face;
    color: $white;
    & .item-caption{
        //text-align: center;
        margin-top:10px;
        margin-bottom: 20px;
        margin-right: 70px;
        font-size: 1.2em;
        width: 100%;
        //border: 2px solid green;
        & strong{
            color:white;
            font-size:1em;
        }
    }

}
@media only screen and (min-width: 768px) and (max-width:1024px) {
    .caption-carousel{
        //border:1px solid red;
        left:20vw;
        top:140px;
        width:60vw;
        font-size: 1rem;

    }


}
@media screen and (max-width: $device-mobile) {
    .h2-manifeste{
        position: absolute;
        z-index: 10;
        top:130px;
        margin-left:2vw;
        //border: 1px solid red;
    }
    .caption-carousel{
        position: absolute;
        z-index: 13;
        //top:$header-height-big;
        left:10vw;
        top:90px;
        width:80vw;
        font-size: 1rem;
        & .item-caption{
            //text-align: center;
            margin-top:10px;
            margin-bottom: 10px;
            margin-right: 70px;
            font-size: 1em;
            line-height: 1em;
            width: 100%;
            //border: 2px solid green;
            & strong{
                color:white;
                font-size:0.9em;
            }
        }
    }

}
@media screen and (max-width: 450px) {
    .h2-manifeste{
        position: absolute;
        z-index: 10;
        top:110px;
        margin-left:30px;
        //text-shadow: 4px 4px 0px rgba(0,0,0,0.3);
        left:10px;
        font-size: 2em;
        color: white;
        text-transform: uppercase;
        font-family: $font-face-title;
        font-weight: $semibold;
        opacity: 0;
    }
    .header-carousel{

        margin:0;
        padding: 0;
        width: 100%;
        height:auto;
        max-height: 520px;
        overflow: hidden;
        //border:1px solid blue;
        //background-color: $dark-blue;
       // background:  linear-gradient(to bottom, rgba(1,1,1,1) 10%, rgba(0,80,111,1) 40%, rgba(0,80,111,1) 84%, rgba(0,0,0,1) 100%);
        //background-blend-mode: multiply;
    }
    .caption-carousel{
        position: absolute;
        z-index: 13;
        //top:$header-height-big;
        left:6vw;
        top:50px;
        width:95vw;
        font-size: 0.8rem;
        & .item-caption{
            //text-align: center;
            margin-top:10px;
            margin-bottom: 10px;
            margin-right: 70px;
            font-size: 1em;
            line-height: 1em;
            width: 100%;
            //border: 2px solid green;
            & strong{
                color:white;
                font-size:0.9em;
            }
        }
    }
}