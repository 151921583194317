@import "./_master.scss";

html{
  margin: 0;
  padding: 0;
  font-family: $font-face;
  font-weight: $bold;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

