@import "../master.scss";
.fix{
    border: 1px solid green;
}
.blue-container{
    margin:0;
    padding:0;
}
.cont-reas{
    margin-bottom: 80px;

}
.rea-figure{
    margin:0;
}

.title-reas{
    color:white !important;
    margin-bottom: 20px;
}
.cont-logos{
    margin-left:5vw;
    margin-right:5vw;
    margin-top: 30px;
    margin-bottom: 70px;
    & div{
        //border :1px solid red;
        display: table-cell !important;
        vertical-align: middle;
        text-align: center;
        & img{
            display: block;
            width:15vw;
            height:auto;
            margin: auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            filter: grayscale(100%);
            //border :1px solid blue;
        }
    }
    
}
.reas-incipit{
    position: relative;
    display: block;
    top:0px;
    left:325px;
    height:auto;
    width: 60%;
    margin-top: 5px;
    margin-bottom: 30px;
    font-family: $font-face;
    font-size: 1em;
    color:white;
    & em{
        font-weight: $bold;
    }
}
.client-incipit{
    position: relative;
    display: block;
    top:0px;
    left:530px;
    height:auto;
    width: 60%;
    margin-top: 5px;
    margin-bottom: 30px;
    font-family: $font-face;
    font-size: 1em;
    color:white;
    font-weight: $bold;

}
.reas-incipit-cont{
    width:100%;
}
.reas-header{
    background-color: $light-blue;
    padding:0px;
    margin-bottom: 0px;
    margin-top:0px;
    //border:1px solid red;
    //margin-left:-5%;
}
.title-reas::after{
    background-color: white;
}
.rea-img{
    position: relative;
    max-width: 70vw;
    width:auto;
    height:auto;
    padding: 0;
    margin: 45px 0 0px 45px;
    cursor: pointer;
   //border: 1px solid red;
}
.alpha{
    opacity: 0.1;
}
.rea-content{
    position: absolute;
    z-index:3;
    //border: 1px solid red;
    top:50px;
    left: 60vw;
    width: 38vw;
    font-family: $font-face-title;
    & h3{
        font-weight: $bold;
        font-size: 2.4em;
        text-transform: uppercase;
    }
}
.c-0{
    color:#ead43e;
}
.c-0::after{
    content:"";
    background-color:#ead43e;
    position:absolute;
    bottom:-5px;
    left:5vw;
    width: 90px;
    height:6px;
}
.c-1{
    color:#ff3a35;
}
.c-2{
    color:#4de0b0;
}
.c-3{
    
    color:#3f4fff;
}
.l-0{
    color:#ead43e;
    //border:blue solid 1px;
}
.l-1{
    color:#ff3a35;
}
.l-2{
    color:#4de0b0;
}
.l-3{  
    color:#3f4fff;
}
.c-1::after{
    content:"";
    background-color:#ff3a35;
    position:absolute;
    bottom: -5px;
    left:5vw;
    width: 90px;
    height:6px;
}
.c-2::after{
    content:"";
    background-color:#4de0b0;
    position:absolute;
    bottom: -5px;
    left:5vw;
    width: 90px;
    height:6px;
}
.c-3::after{
    content:"";
    background-color:#3f4fff;
    position:absolute;
    bottom: -5px;
    left:5vw;
    width: 90px;
    height:6px;
}
.rea-label{
    position: absolute;
    z-index:10;
    left: 65vw;
    top:150px;
    width:30vw;
    font-size: 1.1em;
    font-family: $font-face;
}
.rea-projet{
    margin:0px 0 5px 45px;
    //border:1px solid blue;
    & h4{
        display: inline-block;
        font-family: $font-face;
        font-size: 1em;
        font-weight: $bold;
        width: auto;
        padding-bottom: 5px;
        border-bottom: 3px solid;
    }
}
.form-cont{
    background-color: $light-blue;
    padding:0px;
    margin-bottom: 0px;
    margin-top:0px;
    & .col{
        margin-left:295px;
        & p{
            font-family: $font-face;
            color:white;
            font-weight:$bold;
            font-size: 1.2em;
        }
        & .wpcf7{
           // border:1px solid red;
            margin-top:20px;
            width:40vw;
            & form>p{
                font-size: 1em;
                font-weight:$semibold;
                padding-bottom:5px;
                position: relative;
                & span{
                    display: inline-block;
                    margin-top:5px;
                    & input{
                        border:0px;
                        width:40vw;
                        padding:5px;
                        font-family: $font-face;
                        color: $dark-blue;
                    }
                    & textArea{
                        border:0px;
                        width:40vw;
                        padding:5px;
                        font-family: $font-face;
                        color: $dark-blue;
                        resize: none;
                    }
                }
               
            }
           
            
        }
    }
}
.form-contact{
    margin-top:20px;
    width:45vw;
    & label{
        font-family: $font-face;
        font-size: 1.1em;
        font-weight: $semibold;
        color:white;
    }
}
.wpcf7-submit{
    border:0px;
    background-color: $dark-blue;
    padding:5px;
    color:white;
    font-weight: $bold;
    text-transform: uppercase;
    position: relative;
    width:150px;
    right:0px;
}
.wpcf7-submit:hover{
    background-color: white; 
    color:$dark-blue;
}
.caption-photo{
    font-family: $font-face;
    color: $dark-blue;
    font-size: 1.3em;
    font-weight: $bold;
    line-height: 1em;
    & span{
        font-size:0.7em;
        font-weight: $light;
    }
}
.cover-bio{
    overflow: hidden;
    color: $dark-blue;
    font-size: 1em;
    max-width: 60vw;
    
    & h3{
        font-weight: $bold;
        font-size:1.1em;
        margin-top:20px;
    }
    & p{
        margin:0px;
    }
     
    & ul >li{
        list-style: none;
        font-weight:$normal;
        font-size:1em;
        margin-bottom:3px;
    }
    & li:before{
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        background-image: url('http://de-mots.com/wp/wp-content/uploads/2019/05/puce.png');
        background-size: contain;
        margin-right:5px;
        margin-left:0px;
     }
}
@media screen and (max-width: $device-mobile) {
    .reas-incipit{
       
        left:8vw;
        height:auto;
        width: 80vw;
    }
    .client-incipit{
        left:8vw;
    }
    .form-cont{
        background-color: $light-blue;
        padding:0px;
        margin-bottom: 0px;
        margin-top:0px;
        & .col{
            margin-left:8vw;
        }
        & .wpcf7{
             //border:1px solid red;
             margin-top:20px;
             width:80vw !important;
             & form>p{
                width: 100% !important;
                font-size: 1em;
                font-weight:$semibold;
                padding-bottom:5px;
                position: relative;
                & span{
                    display: inline-block;
                    margin-top:5px;
                    & input{
                        border:0px;
                        width:80vw !important;
                        padding:5px;
                        font-family: $font-face;
                        color: $dark-blue;
                    }
                    & textArea{
                        border:0px;
                        width:80vw !important;
                        padding:5px;
                        font-family: $font-face;
                        color: $dark-blue;
                        resize: none;
                    }
                }
               
            }
           
        }
    }
    .wpcf7-submit{
        border:0px;
        background-color: $dark-blue;
        padding:5px;
        color:white;
        font-weight: $bold;
        font-size: 1.5em;
        text-transform: uppercase;
        position: relative;
        width:77vw;
        height:8vh;
        right:0px;
    }
    .rea-img{
        position: relative;
        max-width: 90vw;
        width:auto;
        height:auto;
        padding: 0;
        margin: 2vw 0 0px 2vw;
        cursor: pointer;
       // border: 1px solid red;
    }
    .rea-content{
        position: absolute;
        z-index:3;
        //border: 1px solid red;
        top:3vw;
        left: 5vw;
        width: 50vw;
        font-family: $font-face-title;
        & h3{
            font-weight: $bold;
            font-size: 2.2em;
            line-height: 1.1em;
            text-transform: uppercase;
        }
        
    }
    .c-0::after{
        content:"";
        bottom:-5px;
        left:0vw;
        width: 120px;
        height:5px;
    }
    .c-1::after{
        content:"";
        bottom:-5px;
        left:0vw;
        width: 120px;
        height:5px;
    }
    .c-2::after{
        content:"";
        bottom:-5px;
        left:0vw;
        width: 120px;
        height:5px;
    }
    .c-3::after{
        content:"";
        bottom:-5px;
        left:0vw;
        width: 120px;
        height:5px;
    }
    .rea-label{
        position: absolute;
        padding:15px;
        z-index:10;
        left: 5vw;
        top:15vw;
        width:85vw;
        font-size: 1em;
        line-height: 1.2em;
        background-color: rgba(white, 0.5);
        font-family: $font-face;
    }
    .rea-projet{
        margin:0px 0 5px 2vw;;
        //border:1px solid blue;
        & h4{
            display: inline-block;
            font-family: $font-face;
            font-size: 1em;
            font-weight: $bold;
            width: auto;
            padding-bottom: 5px;
            border-bottom: 3px solid;
        }
    }
    .form-contact{
        margin-top:20px;
        width:77vw;
        & label{
            font-family: $font-face;
            font-size: 1.1em;
            font-weight: $semibold;
            color:white;
        }
    }
    .cover-bio{
        overflow: hidden;
        color: $dark-blue;
        font-size: 0.9em;
        max-width: 88vw;
        text-align: justify;
        
        & h3{
            font-weight: $bold;
            font-size:1em;
            margin-top:20px;
        }
        & p{
            margin:0px;
        }
        & ul{
            padding-left:10px;
        }
        & ul >li{
            list-style: none;
            font-weight:$normal;
            font-size:0.9em;
            margin-bottom:3px;
        }
        & li:before{
            content: '';
            display: inline-block;
            height: 8px;
            width: 8px;
            background-image: url('http://de-mots.com/wp/wp-content/uploads/2019/05/puce.png');
            background-size: contain;
            margin-right:5px;
            margin-left:0px;
         }
    }
}

@media only screen and (min-width: 768px) and (max-width:1024px) {
    .rea-content{
        position: absolute;
        font-family: $font-face-title;
        left: 61vw;
        & h3{
            font-weight: $bold;
            font-size: 2.2em;
            text-transform: uppercase;
            line-height: 1.1em;
        }
    }
    .rea-label{
        position: absolute;
        z-index:10;
        left: 66vw;
        top:150px;
        width:30vw;
        font-size: 1em;
        font-family: $font-face;
        line-height: 1.2em;
    }

}
@media only screen and(min-width:1200px){
    .rea-img{
        position: relative;
        max-width: 52vw;
        width:auto;
        height:auto;
        padding: 0;
        margin: 45px 0 0px 45px;
        cursor: pointer;
       //border: 4px solid red;
    }
    .rea-content{
        position: absolute;
        z-index:3;
        //border: 2px solid red;
        top:50px;
        left: 47vw;
        width: 50vw;
        font-family: $font-face-title;
        & h3{
            font-weight: $bold;
            font-size: 2.4em;
            text-transform: uppercase;
        }
    }
    .rea-label{
        position: absolute;
        z-index:10;
        left: 52vw;
        top:145px;
        width:34vw;
        font-size: 1.1em;
        font-family: $font-face;
    }
}
@media screen and (max-width: 450px) {

    .rea-content{
        position: absolute;
        z-index:3;
        //border: 1px solid red;
        top:3vw;
        left: 8vw;
        width: 80vw;
        font-family: $font-face-title;
        & h3{
            font-weight: $bold;
            font-size: 1.8em;
            line-height: 1em;
            text-transform: uppercase;
        }
        
    }
    .rea-label{
        position: absolute;
        padding:15px;
        z-index:10;
        left: 5vw;
        top:15vh;
        width:91vw;
        font-size: 1em;
        line-height: 1.2em;
        background-color: rgba(white, 0.7);
        font-family: $font-face;
    }
    .reas-incipit{
        left:15vw;
    }
    .client-incipit{
        left:15vw;
    }
    .cont-logos{
        margin-left:1vw;
        margin-right:1vw;
        margin-top: 30px;
        margin-bottom: 30px;
        & div{
            //border :1px solid red;
            display: table-cell !important;
            vertical-align: middle;
            text-align: center;
            & img{
                display: block;
                width:100%;
                height:auto;
                margin: auto;
                position: relative;
                top: 50%;
                transform: translateY(-50%);
                filter: grayscale(100%);
                //border :1px solid blue;
            }
        }
    }
    .form-cont{
        background-color: $light-blue;
        padding:0px;
        margin-bottom: 0px;
        margin-top:0px;
        & .col{
            margin-left:10vw;
            & p{
                width:75vw;
            }
            & .wpcf7{
                //border:1px solid red;
                margin-top:20px;
                width:75vw !important;
                & form>p{
                    width: 100% !important;
                    font-size: 1em;
                    font-weight:$semibold;
                    padding-bottom:5px;
                    position: relative;
                    & span{
                        display: inline-block;
                        margin-top:5px;
                        & input{
                            border:0px;
                            width:75vw !important;
                            padding:5px;
                            font-family: $font-face;
                            color: $dark-blue;
                        }
                        & textArea{
                            border:0px;
                            width:75vw !important;
                            padding:5px;
                            font-family: $font-face;
                            color: $dark-blue;
                            resize: none;
                        }
                    }
                }
            }
        }
    }
    .wpcf7-submit{
        border:0px;
        background-color: $dark-blue;
        padding:5px;
        color:white;
        font-weight: $bold;
        font-size: 1.5em;
        text-transform: uppercase;
        position: relative;
        width:75vw;
        height:8vh;
        right:0px;
    }
    .form-contact{
        margin-top:20px;
        width:75vw;
        & label{
            font-family: $font-face;
            font-size: 1em;
            font-weight: $semibold;
            color:white;
        }
    }
}