@import "../master.scss";
.fix{
    border: 1px solid green;
}
.cont-approche{
    margin-bottom: 80px;
    //border: 1px solid red;

}

.title-approche{
    color:$light-blue !important;
    position: relative;
    margin:20px 0px 20px 0px;
    padding:0;
    // border: 1px solid red;
    top:0;
    left:0px;
    display: inline-block;
}
.app-incipit{
    position: relative;
    display: block;
    top:0px;
    left:320px;
    height:auto;
    width: 60%;
    margin-bottom: 40px;
    font-family: $font-face;
    color:$dark-blue;
    & em{
        font-weight: $bold;
    }
}
.app-row{
    //border:1px solid red;
    margin-left:50px;
    margin-right:50px;
}
.app-card{
    border:none;
    border-radius: 0px;
    cursor: pointer;
    
}
.card-p{
    margin-bottom: 0px;
    padding: 0 10px 10px 10px;
    line-height: 1.2em;
    //border: 1px solid red;
}
.item-0{
    color:#43e1af;
    //border: 1px solid red;
}
.item-1{
    color:#ff1400;
   // border: 1px solid red;
}
.item-2{
    color:#3c44fd;
    //border: 1px solid red;
}
.item-3{
    color:#e5d028;
   // border: 1px solid red;
}
.app-card-label div{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    //visibility:hidden;
    //opacity:0;
}
.fond-card{
    background-color: #F9F9F9;
    opacity: 0.9;
   // border: #ff1400 1px solid;
}
.card-img{
    //border: 1px solid red;
    border-radius: 0%;
    height: auto;
}
.card-over-0{
    position: absolute;
    background-color: white;
    top:150px;
    left: 50px;
    padding:10px;
    //margin-left: calc(-100vw / 2 + 600px / 2);
    //margin-right: calc(-100vw / 2 + 600px / 2);
    width:700px;
    z-index: 200;
    font-size: 0.9em;
    color:#43e1af;
    //visibility: hidden;
}
.card-over-1{
    position: absolute;
    background-color: white;
    top:150px;
    left: 50px;
    padding:10px;
    //margin-left: calc(-100vw / 2 + 600px / 2);
    //margin-right: calc(-100vw / 2 + 600px / 2);
    width:700px;
    z-index: 200;
    font-size: 0.9em;
    color:#ff1400;
    //visibility: hidden;
}
.card-over-2{
    position: absolute;
    background-color: white;
    top:150px;
    left: -100px;
    padding:10px;
    //margin-left: calc(-100vw / 2 + 600px / 2);
    //margin-right: calc(-100vw / 2 + 600px / 2);
    width:600px;
    z-index: 200;
    font-size: 0.9em;
    color:#3c44fd;
    //visibility: hidden;
}
.card-over-3{
    position: absolute;
    background-color: white;
    top:150px;
    right: 30px;
    padding:10px;
    //margin-left: calc(-100vw / 2 + 600px / 2);
    //margin-right: calc(-100vw / 2 + 600px / 2);
    width:700px;
    z-index: 200;
    font-size: 0.9em;
    color:#e5d028;
    //visibility: hidden;
}
.app-card-label h3{
    position: absolute;
    top:40%;
    width:100%;
    //border: 1px solid red;
    font-family: $font-face-title;
    font-weight: $bold;
    font-size: 1.4em;
    text-transform:uppercase;
    text-align: center;
    opacity: 1;
}
@media screen and (max-width: $device-mobile) {
    .app-incipit{
        top:0px;
        left:10vw;
        height:auto;
        width: 80vw;
        margin-bottom: 40px;
        font-family: $font-face;
        color:$dark-blue;
        & em{
            font-weight: $bold;
        }
    }
    .app-row{
        margin-left:2vw;
    }
    .app-row>div{
        //border:1px solid red;
        margin-bottom: 10px;
        width:40vw;
       
    }
    .card-over-0{
        top:120px;
        left:5vw;
        width:90vw;
    }
    .card-over-1{
        top:120px;
        left:-45vw !important;
        width:90vw;
    }
    .card-over-2{
        top:120px;
        left:5vw;
        width:90vw;
    }
    .card-over-3{
        top:120px;
        right:-5vw;
        width:90vw;
    }
    .app-card{
        width:30vw;
        margin:auto;
    }
}
@media only screen and (min-width: 768px) and (max-width:1024px) {
    .app-row{
        //border:1px solid red;
        margin-left:5vw;
        margin-right:5vw;
    }
  
    .card-p{
        font-size: 1em;
    }
    .app-card-label h3{
        font-size: 1.2em;
    }
    .card-over-0{
        top:120px;
    }
    .card-over-1{
        position: absolute;
        background-color: white;
        top:120px;
        left: 10px;
        padding:10px;
        //margin-left: calc(-100vw / 2 + 600px / 2);
        //margin-right: calc(-100vw / 2 + 600px / 2);
        width:650px;
        z-index: 200;
        font-size: 0.9em;
        color:#ff1400;
        //visibility: hidden;
    }
    .card-over-2{
        position: absolute;
        background-color: white;
        top:120px;
        left: -100px;
        padding:10px;
        //margin-left: calc(-100vw / 2 + 600px / 2);
        //margin-right: calc(-100vw / 2 + 600px / 2);
        width:600px;
        z-index: 200;
        font-size: 0.9em;
        color:#3c44fd;
        //visibility: hidden;
    }
    .card-over-3{
         top:120px;
    }
}
@media screen and (max-width: 450px) {
    .cont-approche{
        margin-bottom: 10px;
        padding: 0;
        margin-left:0px;
        margin-right: 0px;
    
    }
    .card-over-0{
        top:70px;
        width:100vw;
        left:-5vw;
        padding:5px;
    }
    .card-over-1{
        position: absolute;
        background-color: white;
        top:80px;
        left: 5vw;
        padding:5px;
        //margin-left: calc(-100vw / 2 + 600px / 2);
        //margin-right: calc(-100vw / 2 + 600px / 2);
        width:90w;
        z-index: 200;
        font-size: 0.9em;
        color:#ff1400;
        //visibility: hidden;
    }
    .card-over-2{
        position: absolute;
        background-color: white;
        top:120px;
        left: -5vw;
        padding:5px;
        //margin-left: calc(-100vw / 2 + 600px / 2);
        //margin-right: calc(-100vw / 2 + 600px / 2);
        width:100vw;
        z-index: 200;
        font-size: 0.9em;
        color:#3c44fd;
        //visibility: hidden;
    }
    .card-over-3{
         top:120px;
         padding:5px;
         left: -50vw;
         width:100vw;
    }
    .app-incipit{
        top:0px;
        left:0vw;
        height:auto;
        width: 75vw;
        margin-bottom: 20px;
        font-family: $font-face;
        color:$dark-blue;
        & em{
            font-weight: $bold;
            
        }
    }
    .app-row{
        //border:1px solid red;
        margin-left:0vw;
        margin-right:0vw;
        margin-bottom: 0px;
    }
    .app-row>.col{
       // border: 1px solid green;
        cursor: pointer;
        margin-bottom: 30px;
    }
    .card-p{
        font-size: 0.9em;
    }
    .app-card-label h3{
        font-size: 1em;
    }
    .app-incipit{
        left:12vw;
    }

}