@import "../master.scss";
@import "../../node_modules/video-react/dist/video-react.css";
.project-viewer-0{
    //border: 1px solid red;
    position:absolute;
    z-index:2;
    top:50px;
    left: 60px;
    width: 70vw;
    height:auto;
    max-height: 72vh;
    background-color: black;
    overflow: hidden;
    & .carousel-indicators{
        position: absolute !important;
        bottom: -1vw;
         & li{
            opacity: 1;
            filter: drop-shadow(1 1 10px #333);
         }
         & .active{
            background-color:#ead43e;
        }   
    }
}
.project-viewer-1{
    //border: 1px solid red;
    position:absolute;
    z-index:2;
    top:50px;
    left: 60px;
    width: 70vw;
    height:auto;
    max-height: 72vh;
    background-color: black;
    overflow: hidden;
    & .carousel-indicators{
        position: absolute !important;
        bottom: -1vw;
         & li{
            opacity: 1;
            filter: drop-shadow(1 1 10px #333);
         }
         & .active{
            background-color:#ff3a35;
        }   
    }
}
.project-viewer-2{
    //border: 1px solid red;
    position:absolute;
    z-index:2;
    top:50px;
    left: 60px;
    width: 70vw;
    height:auto;
    max-height: 72vh;
    background-color: black;
    overflow: hidden;
    & .carousel-indicators{
        position: absolute !important;
        bottom: -1vw;
         & li{
            opacity: 1;
            filter: drop-shadow(1 1 10px #333);
         }
         & .active{
            background-color:#4de0b0;
        }   
    }
}
.project-viewer-3{
    //border: 1px solid red;
    position:absolute;
    z-index:2;
    top:50px;
    left: 60px;
    width: 70vw;
    height:auto;
    max-height: 72vh;
    background-color: black;
    overflow: hidden;
    & .carousel-indicators{
        position: absolute !important;
        bottom: -1vw;
         & li{
            opacity: 1;
            filter: drop-shadow(1 1 10px #333);
         }
         & .active{
            background-color:#3f4fff;
        }   
    }
}
.exit{
    font-family: $font-face-title;
    font-weight: $bold;
    font-size: 1.5rem;
    position: absolute;
    z-index: 4;
    top:60px;
    left:80px;
    cursor:pointer;
}
.h3-0{
    color:$dark-blue;
    display: inline-block;
    //border:1px solid red;
}
.h3-1{
    color:$dark-blue;
    display: inline-block;
    //border:1px solid red;
}
.h3-2{
    color:$dark-blue;
    display: inline-block;
    //border:1px solid red;
}
.h3-3{
    color:$dark-blue;
    display: inline-block;
    //border:1px solid red;
}
.h3-0::after{
    content:'';
    position: absolute;
    bottom: -12px;
    width: 70px;
    height: 5px;
    left:2px;
    background-color:#ead43e;
}
.h3-1::after{
    content:'';
    position: absolute;
    bottom: -12px;
    width: 70px;
    height: 5px;
    left:2px;
    background-color:#ff3a35;
}
.h3-2::after{
    content:'';
    position: absolute;
    bottom: -12px;
    width: 70px;
    height: 5px;
    left:2px;
    background-color:#4de0b0;
}
.h3-3::after{
    content:'';
    position: absolute;
    bottom: -12px;
    width: 70px;
    height: 5px;
    left:2px;
    background-color:#3f4fff;
}
.c0{
    color:#ead43e;
}
.c1{
    color:#ff3a35;
}
.c2{
    color:#4de0b0;
}
.c3{  
    color:#3f4fff;
}
.item-conteneur{
    //border:1px solid yellow;
    height:100%;
   // margin-top:6%; 
}
.projet-img{
    max-width: 100%;
    width:auto;
    height:auto;
    padding: 0;
    display: block;
    margin: 0 auto;
   // border: 1px solid red;
}
.projet-p{
    position: absolute;
    z-index: 20;
    max-width:40vw;
    left:76vw;
    top:200px;
    font-size: 0.9rem;
    color:$dark-blue;
    padding-left: 3px;
    max-height: 500px;
    overflow:scroll;
    & h3{
        font-size: 1.1em;
        font-weight: $semibold;
    }
    //border: 1px solid red;
}
.title-project{
    position: absolute;
    z-index:15;
    left:76vw;
    top:150px;
    font-size: 1.1em;
    font-weight: $semibold;
}
.video-project{
    //width:85vw !important;
    //margin-top:7vh
}
.video-react .video-react-big-play-button{
    position: absolute;;
    z-index:30;
    //border: 1px solid red;
    width:10vw !important;
    height:10vw !important;
    margin-top: 21vh;
    margin-left:30vw;
    text-align: center;
    font-size: 6em !important;
    color:white;
    background-color: $dark-blue !important;
    border-radius: 5vw !important;
    padding-top:5vw;
}
.video-react-control-bar{
    background-color: rgba($dark-blue, .9) !important;
    font-family: $font-face !important;
}

.carousel-control-prev {
    //border:1px solid red;
    height:54vh;
    & .carousel-control-prev-icon{
        //border:1px solid red;
        //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
        margin-top: 50px;
        width: 30px;
        height: 30px;
        opacity: 1;

    }
}
.carousel-control-next {
   
    height:54vh;
    & .carousel-control-next-icon{
        //border:1px solid red;
        //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
        margin-top: 50px;
        width: 30px;
        height: 30px;
        opacity: 1;

    }
}
@media screen and (max-width: $device-mobile) {
    .title-project{
        position: absolute;
        z-index:15;
        left:10vw;
        top:4vh;
        font-size: 1.5em;
        font-weight: $semibold;
    }
    .projet-p{
        position: absolute;
        padding: 10px;
        margin: 0;
        z-index: 20;
        max-width:92vw;
        left:4vw;
        top:68vh;
        font-size: 0.9rem;
        line-height: 1.2;
        color:$dark-blue;
        max-height: 200px;
        color: white;
        background-color: rgba($dark-blue, .9);
        overflow:scroll;
        //border: 1px solid red;
        & h3{
            font-size: 1em;
            font-style: italic;
            font-weight: $semibold;
        }
    }
    .exit{
        font-family: $font-face-title;
        font-weight: $bold;
        font-size: 2rem;
        position: absolute;
        z-index: 5;
        top:3vh;
        left:5vw;
        cursor:pointer;
    }
    .project-viewer-0{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 4vw;
        width: 92vw;
        max-height: 65vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#ead43e;
            }   
        }
    }
    .project-viewer-1{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 4vw;
        width: 92vw;
        max-height: 65vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#ff3a35;;
            }   
        }
    }
    .project-viewer-2{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 4vw;
        width: 92vw;
        max-height: 65vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#4de0b0;
            }   
        }
    }
    .project-viewer-3{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 4vw;
        width: 92vw;
        max-height: 65vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#3f4fff;
            }   
        }
    }

}
.video-react-video{
   // margin-top0vh !important;
    
}
@media only screen and (min-width: 768px) and (max-width:1024px) {
    .title-project{
        position: absolute;
        z-index:15;
        left:72vw;
        top:140px;
        font-size: 1em;
        font-weight: $semibold;
    }
    .projet-p{
        position: absolute;
        z-index: 20;
        max-width:40vw;
        left:74vw;
        top:180px;
        font-size: 0.8rem;
        color:$dark-blue;
        line-height: 1.2em;
        padding-left: 3px;
        max-height: 60vh;;
        overflow:scroll;
        background-color: rgba($color:white, $alpha: 0.8);
        //border: 1px solid red;
        & ul{
            padding: 15px;

        }
        & li{
            padding-left:0px; 
            margin-bottom: 5px;
        }
    }
    .video-react .video-react-big-play-button{
        position: absolute;;
        z-index:30;
        //border: 1px solid red;
        width:10vw !important;
        height:10vw !important;
        margin-top: 18vh;
        margin-left:29vw;
        //margin:auto;
        text-align: center;
        font-size: 5em !important;
        color:white;
        background-color: $dark-blue !important;
        border-radius: 5vw !important;
        padding-top:5vw;
    }
    .carousel-control-prev {
        //border:1px solid red;
        height:45vh;
        & .carousel-control-prev-icon{
            //border:1px solid red;
            //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
            margin-top: 50px;
            width: 30px;
            height: 30px;
            opacity: 1;
    
        }
    }
    .carousel-control-next {
       
        height:45vh;
        & .carousel-control-next-icon{
            //border:1px solid red;
            //box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.8);
            margin-top: 50px;
            width: 30px;
            height: 30px;
            opacity: 1;
    
        }
    }
}
@media only screen and(min-width:1200px){
    .project-viewer-0{
        //border: 1px solid red;
        position:absolute;
        z-index:2;
        top:50px;
        left: 60px;
        width: 52vw;
        height:auto;
        max-height: 530px;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#ead43e;
            }   
        }
    }
    .project-viewer-1{
        //border: 1px solid red;
        position:absolute;
        z-index:2;
        top:50px;
        left: 60px;
        width: 52vw;
        height:auto;
        max-height: 530px;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#ff3a35;
            }   
        }
    }
    .project-viewer-2{
        //border: 1px solid red;
        position:absolute;
        z-index:2;
        top:50px;
        left: 60px;
        width: 52vw;
        height:auto;
        max-height: 530px;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#4de0b0;
            }   
        }
    }
    .project-viewer-3{
        //border: 1px solid red;
        position:absolute;
        z-index:2;
        top:50px;
        left: 60px;
        width: 52vw;
        height:auto;
        max-height: 530px;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#3f4fff;
            }   
        }
    }
    .projet-p{
        position: absolute;
        z-index: 20;
        max-width:42vw;
        left:57vw;
        top:200px;
        font-size: 0.9rem;
        color:$dark-blue;
        padding-left: 3px;
        max-height: 500px;
        overflow:scroll;
        //border: 1px solid red;
    }
    .title-project{
        position: absolute;
        z-index:15;
        left:57vw;
        top:160px;
        font-size: 1.1em;
        font-weight: $semibold;
    }
  
    .video-react .video-react-big-play-button{
        position: absolute;;
        z-index:30;
        //border: 1px solid red;
        width:8vw !important;
        height:8vw !important;
        margin-top: 21vh;
        margin-left:22vw;
        text-align: center;
        font-size: 7.5em !important;
        color:white;
        background-color: $dark-blue !important;
        border-radius: 4vw !important;
        padding-top:5vw;
    }
}
@media screen and (max-width: 450px) {
    .title-project{
        position: absolute;
        z-index:15;
        left:8vw;
        top:3vh;
        font-size: 1.4em;
        font-weight: $semibold;
    }
    .projet-p{
        position: absolute;
        padding: 10px;
        margin: 0;
        z-index: 20;
        max-width:96vw;
        left:2vw;
        top:35vh;
        font-size: 0.9rem;
        line-height: 1.2;
        color:$dark-blue;
        max-height: 40vh;
        color: white;
        background-color: rgba($dark-blue, .9);
        overflow:scroll;
        //border: 1px solid red;
        & h3{
            font-size: 0.95em;
            font-style: italic;
            font-weight: $semibold;
        }
        & ul{
            padding: 11px;

        }
        & li{
            padding-left:0px; 
            margin-bottom: 5px;
        }
    }
    .exit{
        font-family: $font-face-title;
        font-weight: $bold;
        font-size: 1.8rem;
        position: absolute;
        z-index: 5;
        top:2vh;
        left:3vw;
        cursor:pointer;
    }
    .project-viewer-0{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 2vw;
        width: 96vw;
        max-height: 33vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#ead43e;
            }   
        }
    }
    .project-viewer-1{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 2vw;
        width: 96vw;
        max-height: 33vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#ff3a35;;
            }   
        }
    }
    .project-viewer-2{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 2vw;
        width: 96vw;
        max-height: 33vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#4de0b0;
            }   
        }
    }
    .project-viewer-3{
        //border: 1px solid red;
        position:absolute;
        z-index:4;
        top:3vw;
        left: 2vw;
        width: 96vw;
        max-height: 33vh;
        height:100%;
        background-color: black;
        overflow: hidden;
        & .carousel-indicators{
            position: absolute !important;
            bottom: -1vw;
             & li{
                opacity: 1;
                filter: drop-shadow(1 1 10px #333);
             }
             & .active{
                background-color:#3f4fff;
            }   
        }
    }
    .carousel-control-prev {
        // border:1px solid red;
         height:24vh;
     }
     .carousel-control-next {
        // border:1px solid red;
         height:24vh;
     }
     .video-react .video-react-big-play-button{
        position: absolute;;
        z-index:30;
        //border: 1px solid red;
        width:30vw !important;
        height:30vw !important;
        margin-top: 8vh;
        margin-left:28vw;
        text-align: center;
        font-size: 7.5em !important;
        color:white;
        background-color: $dark-blue !important;
        border-radius: 15vw !important;
        padding-top:5vw;
    }
}