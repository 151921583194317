.row-propos{
    margin:30px;
}
.img-r{
    padding-right:15px;
    padding-bottom:18px;
    width: 100%;
    height: auto;

}
.img-fond-r{
    background-image: url("http://de-mots.com/wp/wp-content/uploads/2019/05/bg-blue.png");
    background-size: 150px 150px;
    background-repeat:no-repeat;
    background-position: 100% 100%;
    padding-right:10px;
    padding-bottom:10px;
}