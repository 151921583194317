@import "../_master.scss";

.de-mots-header{
    font-family: $font-face;
    font-weight: $bold;
    height:$header-height-big;
   // border:1px solid red;
    //background:linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background: rgba(255,255,255,1);
    transition : height 0.5s, background-color 0.5s,padding-top 0.5s;
    & .logo-header{
        position: absolute;
        height: 137px;
        left:60px;
        top:5px;
        filter:initial;
        transition : height 0.5s, filter 0.5s;

    }
}
.de-mots-header-small{
    font-family: $font-face;
    font-weight: $bold;
    height:$header-height-small;
    padding-top:0px;
   // border:1px solid red;
    //background:linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
    background: $dark-blue;
    transition : height 0.5s, background-color 0.5s;
    & .logo-header{
        position: absolute;
        left:60px;
        top:8px;
        height:60px;
        filter: brightness(0) invert(1);
        transition : height 0.5s, filter 0.5s,scale 0.5s,top 0.5s;
    }
    & .header-menu{
        //border: 1px solid red;
        margin-top:0px;
        padding-top:0px;
        & .header-items{
            color:$light-blue !important;
        }
        & .header-items-active{
            color:white !important;
        }
        & .header-items:hover{
            color:white !important;
        }
        & .header-items:hover::after{
            background-color: white;
            transition : background-color 0.5s;
        }
    }
}
.header-baseline{
    //border : 1px solid red;
    width :100%;
    display: flex;
    justify-content: flex-end;
    & p{
        clear: both;
        display: block;
        font-family: $font-face-title;
    }
}
.header-bloc{
   //border: 1px solid green;
   //display: flex;
   //flex-direction: row;
   //flex-flow: row;
   //align-items: flex-end !important;
}

.header-menu{
    width: 100%;    
    //border : 1px solid blue;
    margin-top:40px;
    display: flex !important;
    justify-content: flex-end !important;
    & ul{
        display:table;
        //border: 1px solid red;
        height: 100%;
        line-height: 0.3em;
    }
    & .header-items{
        width:150px;
        display: inline-block;
        color: $light-blue !important;
        font-size: 1.1em;
        text-align: center;
        //border: 2px $light-blue solid;
        margin-top: 10px;
        padding-top: 10px;
        line-height: 0.8em;
        transition:color 0.5s
    
    }
    & .header-items-active{
        width:150px;
        display: inline-block;
        color: $dark-blue !important;
        font-size: 1.1em;
        text-align: center;
        //border: 2px $light-blue solid;
        margin-top: 10px;
        padding-top: 10px;
        line-height: 0.8em;
        transition:color 0.5s
    
    }
    & .header-items:hover{
        //background-color: $light-blue;
        color:$dark-blue !important;
    }
    & .header-items::after{
        content:" ";
        position: relative;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0);
        width: 80px;
        height: 5px;
        margin-top: 10px;
        transition : background-color 0.5s, width 0.5s;

    }
    & .header-items-active::after{
        content:" ";
        display: inline-block;
        background-color: $light-blue;
        width: 80px;
        height: 5px;;

    }
    .header-items:hover::after{
        content:" ";
        position: relative;
        display: inline-block;
        background-color: $dark-blue;
        width: 100px;
        height: 5px;
        margin-top: 10px;
        transition : background-color 0.5s;

    }

}
@media screen and (max-width: $device-mobile) {
    .de-mots-header{
        height: $header-height-small;
        margin: 0;
        padding:0;
       
        & .logo-header{
         
            height:80px;
        }
    }
    .de-mots-header-small{
        margin: 0;
        padding:0;
        & .menu-burger{
            border-color : $dark-blue !important;
            margin-right:20px;
            margin-top:10px;
            background-color:white;
            & .navbar-toggler-icon {
             background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,79,111, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
           }
         }
         & .de-mots-items{
            background-color: $dark-blue;
            width: 100vw;
            margin: 0;
            padding:0;
        }
    }
    .menu-burger{
       border-color : $dark-blue !important;
       margin-right:20px;
       margin-top:10px;
       background-color:white;
       & .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,79,111, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
      }
    }
    .menu-burger-opened{
        background-color:$dark-blue;
        margin-right:20px;
        margin-top:10px;
        & .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
          }
    }

    .de-mots-items{
        background-color: white;
        width: 50px;
        //border:1px solid red;
        margin-top: 30px;
    }
    .header-baseline{
        display: flex;
        flex-flow: column;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        float: none;
        vertical-align: top;
        width:50%;
        & .header-items{
            margin-right: 40px;
            margin-top:2px;
            margin-bottom: 10px;
            text-align: right;
            line-height: 0.5em;
            font-size: 1em;
        }
        & .header-items::after{
            content:" ";
            position:relative;
            display: block;
            background-color: $light-blue;
            width: 70px;
            height: 5px;
            left:70px;
            margin-left: 10px;
            margin-bottom: -4px;
    
        }
        & .header-items:hover::after{
            content:" ";
            display: inline-block;
            background-color: $dark-blue;
            width: 3px;
            height: 20px;
    
        }
        & .header-items-active::after{
            content:" ";
            display: block;
            background-color: $light-blue;
            position:relative;
            width: 80px;
            height: 5px;
            width: 70px;
            height: 5px;
            left:30px;
            margin-left: 10px;
            margin-top: 10px;
            margin-bottom: 5px;
            
    
        }
        & .header-items:hover{
            //background-color: $light-blue;
            color:$dark-blue !important;
            margin-right: 30px;
            line-height: 0.5em;
        }
    }
    
    .hidden-menu{
        visibility: hidden;
    }
}
.invisible-burger{
    opacity: 0;
}
@media only screen and (min-width: 768px) and (max-width:1024px) {
    .header-menu{
       //border:1px solid red;
        font-size: 1em;
        &  .header-items{
            width:100px;
            font-size: 1em;
        }
        & .header-items-active{
            width:100px;
            font-size: 1.1em;
        }
    }

}
@media screen and (max-width: 450px) {
    .de-mots-header{
        font-family: $font-face;
        font-weight: $bold;
        height:100px;
       //border:1px solid red;
        //background:linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
        background: rgba(255,255,255,1);
        transition : height 0.5s, background-color 0.5s,padding-top 0.5s;
        & .logo-header{
            position: absolute;
            height: 80px;
            left:20px;
            top:5px;
            filter:initial;
            transition : height 0.5s, filter 0.5s;
    
        }
    }
    .de-mots-header-small{
        font-family: $font-face;
        font-weight: $bold;
        height:80px;
        padding-top:0px;
       // border:1px solid red;
        //background:linear-gradient(to bottom, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 100%);
        background: $dark-blue;
        transition : height 0.5s, background-color 0.5s;
        & .logo-header{
            position: absolute;
            left:0px;
            top:10px;
            height:50px;
            filter: brightness(0) invert(1);
            transition : height 0.5s, filter 0.5s,scale 0.5s,top 0.5s;
        }
    }

}
